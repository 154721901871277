@import 'samespace-ui-kit/scss/_base.scss';

body {
  overflow-y: scroll !important;
}

.app-container {
  .app-container {
    min-height: 0;
    padding-top: 0;
  }
  & > .loader {
    top: 0;
  }
}

.app {
  &.is-in-iframe {
    & > .app-container {
      padding-top: 0;
    }
    .page-wrapper {
      min-height: 100vh;
    }
    .subheader {
      top: 0;
    }
    .page-error {
      min-height: 100vh;
    }
    .loader {
      top: 0;
      &--with-subheader {
        top: $subheader_height;
      }
    }
    .lazy-loader {
      top: 0;
    }
    .login {
      margin-top: 0;
    }
    .invite-section-wrapper {
      margin-top: 0;
    }
    .card-section {
      &--sticky {
        top: 0;
        &--subheader {
          top: $subheader_height;
        }
      }
    }
  }
}
