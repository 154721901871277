.date-popover {
  &__trigger {
    display: inline-block;
  }
}

.DatePopover {
  z-index: 12500;
  .Popover-body {
    padding: 1rem;
    background: white;
    border: 1px solid mix(#000033, #fff, 10%);
    border-radius: 0.5rem;
    border-top: 0px;
    box-shadow: 0 10px 20px 0 rgba(4, 7, 28, 0.08),
      10px 0px 40px rgba(0, 0, 0, 0.1);
  }
  .Popover-tip {
    z-index: 12501;
    fill: white;
  }
  .Popover-left {
    .Popover-tip {
      margin-left: -1px;
    }
  }
  .Popover-right {
    .Popover-tip {
      margin-right: -1px;
    }
  }
  .Popover-above {
    .Popover-tip {
      margin-top: -1px;
    }
  }
  .Popover-below {
    .Popover-tip {
      margin-bottom: -1px;
    }
  }
}
