@import 'samespace-ui-kit/scss/_base.scss';

$login-box-width: 400px;
$login-box-height: 540px;
$login-box-v-padding: 40px;
$login-box-h-padding: 64px;
$login-logo-height: 32px;
$login-logo-margin: 40px;
$login-content-height: $login-box-height - (2 * $login-box-v-padding) -
  $login-logo-height - $login-logo-margin + 10;
$signup-box-height: 668px;
$signup-content-height: $signup-box-height - (2 * $login-box-v-padding) -
  $login-logo-height - $login-logo-margin;

@mixin forwidth($max: 0, $min: 0) {
  @if $min==0 {
    @media (max-width: $max+'px') {
      @content;
    }
  } @else if $max==0 {
    @media (min-width: $min+'px') {
      @content;
    }
  } @else {
    @media (min-width: $min+'px') and (max-width: $max+'px') {
      @content;
    }
  }
}

.initial-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  position: absolute;
  height: 100vh;
}
